import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const AttentionBanner = ({ banners, className }) => {
  const now = new Date();
  const banner =
    banners &&
    banners.find(banner => {
      const startDate = new Date(banner.startDate);
      const endDate = new Date(banner.endDate);

      return startDate <= now && now <= endDate;
    });

  if (!banner) {
    return null;
  }

  return (
    <Container
      className={className}
      dangerouslySetInnerHTML={{ __html: banner.text }}
    />
  );
};

AttentionBanner.propTypes = {
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired
    })
  ).isRequired,
  className: PropTypes.string
};

export default AttentionBanner;
