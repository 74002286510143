import 'mdn-polyfills/Object.assign';
import 'mdn-polyfills/String.prototype.startsWith';
import 'mdn-polyfills/String.prototype.endsWith';
import 'promise-polyfill/src/polyfill';
import React from 'react';
import { hydrate, render } from 'react-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

if (module.hot) {
  module.hot.accept('./app', () => {
    const NextApp = require('./app').default;
    render(<NextApp />, rootElement);
  });
}

serviceWorker.unregister();
//
// if (navigator.userAgent === 'ReactSnap') serviceWorker.unregister();
// else serviceWorker.register();
