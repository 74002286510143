import styled from '@emotion/styled';
import { Candy, U3 } from '../../styles/colours';

export const Container = styled.div`
  background-color: ${Candy};
  color: white;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  padding: 10px 15px;
  text-align: center;
  a {
    color: ${U3};
  }
`;
