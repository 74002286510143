import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import useLogging from '../use-logging';

const Analytics = ({ location: { pathname } = {} }) => {
  const [segmentId] = useState(() => process.env.REACT_APP_SEGMENT_ID);
  const { trace } = useLogging();
  useEffect(() => {
    if (segmentId && pathname && window.analytics) {
      window.analytics.page();
      trace('Navigation tracked');
    }
  }, [pathname, segmentId]);

  return null;
};

export default withRouter(Analytics);
