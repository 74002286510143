import { useState, useCallback } from 'react';

export default () => {
  const [minLogLevel] = useState(
    () => process.env.REACT_APP_MIN_LOG_LEVEL || 'error'
  );
  let level = null;
  switch (minLogLevel.toLowerCase()) {
    case 'error':
      level = 3;
      break;
    case 'warn':
      level = 2;
      break;
    case 'info':
      level = 1;
      break;
    case 'trace':
      level = 0;
      break;
    default:
      level = 3;
  }
  const trace = useCallback(
    (message, data) => {
      if (level > 0) return;
      console.trace(message, data);
    },
    [minLogLevel]
  );
  const info = useCallback(
    (message, data) => {
      if (level > 1) return;
      console.info(message, data);
    },
    [minLogLevel]
  );
  const warn = useCallback(
    (message, data) => {
      if (level > 2) return;
      console.warn(message, data);
    },
    [minLogLevel]
  );
  const error = useCallback(
    (message, data) => {
      if (level > 3) return;
      console.error(message, data);
    },
    [minLogLevel]
  );

  return {
    trace,
    info,
    warn,
    error,
    minLogLevel,
    level
  };
};
