export const Candy = '#FF1E7E';

export const Midnight = '#00233D';

export const Mountain = '#718594';

export const Mustard = '#ffd112';

export const Bubblegum = '#70CAF2';

export const U1 = '#D6DADE';
export const U2 = '#F8FAFC';
export const U3 = '#FFC0E4';
export const U4 = '#EDF1F4';
export const U5 = '#D5DADE';
