import React, { Suspense, lazy, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import ScrollToTop from './utilities/scroll-to-top';
import AttentionBanner from './components/attention-banner';
import GlobalStyles from './styles/global';
import * as links from './utilities/links';
import { Analytics } from './utilities/analytics';

const Home = lazy(() => import('./containers/home'));
const Calculators = lazy(() => import('./containers/home-loan-calculator'));

const AffordabilityCalculator = lazy(() =>
  import('./containers/affordability-calculator')
);
const RepaymentCalculator = lazy(() =>
  import('./containers/repayment-calculator')
);
const Learn = lazy(() => import('./containers/learn'));
const Webinars = lazy(() => import('./containers/webinars'));
const Story = lazy(() => import('./containers/story'));
const Apply = lazy(() => import('./containers/apply'));
const Prequalify = lazy(() => import('./containers/prequalify'));
const Terms = lazy(() => import('./containers/terms-and-conditions'));
const Declarations = lazy(() =>
  import('./containers/application-declarations')
);
const PrequalDeclarations = lazy(() =>
  import('./containers/prequal-declarations')
);
const Testimonials = lazy(() => import('./containers/testimonials'));
const FourOh4 = lazy(() => import('./containers/four-oh-4'));

const Loading = <div />;

export default () => {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    try {
      if (window.process.env.REACT_APP_BANNERS_JSON) {
        const b = JSON.parse(
          window.decodeEntities(window.process.env.REACT_APP_BANNERS_JSON)
        );
        setBanners(b);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <>
      <GlobalStyles />
      <Router>
        <Suspense fallback={Loading}>
          <ScrollToTop>
            <AttentionBanner banners={banners} />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path={links.calculators()} exact component={Calculators} />
              <Route
                path={links.affordability()}
                exact
                component={AffordabilityCalculator}
              />
              <Route
                path={links.repayment()}
                exact
                component={RepaymentCalculator}
              />
              <Route path={links.learn()} exact component={Learn} />
              <Route path={links.webinars()} exact component={Webinars} />
              <Route path={links.story()} exact component={Story} />
              <Route path={links.apply()} exact component={Apply} />
              <Route path={links.prequalify()} exact component={Prequalify} />
              <Route
                path={links.testimonials()}
                exact
                component={Testimonials}
              />
              <Route
                path={links.declarations()}
                exact
                component={Declarations}
              />
              <Route
                path={links.prequalDeclarations()}
                exact
                component={PrequalDeclarations}
              />
              <Route path={links.terms()} exact component={Terms} />
              <Route path="/privacy-policy">
                <Redirect to={links.privacy()} />
              </Route>
              <Route component={FourOh4} />
            </Switch>
          </ScrollToTop>
        </Suspense>
        <Analytics />
      </Router>
    </>
  );
};
