import React from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    const { pathname, hash } = this.props.location;
    if (pathname !== prevProps.location.pathname)
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0
        });
      }, 0);

    if (hash !== prevProps.location.hash) {
      setTimeout(() => {
        if (hash) {
          const element = document.getElementById(hash.replace('#', ''));
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          });
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }
      }, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
