// const applicationUIBase = () => {
//   let base = window.process.env.REACT_APP_APPLICATION_UI_URL;
//   if (navigator.userAgent === 'ReactSnap')
//     base = '${' + 'REACT_APP_APPLICATION_UI_URL' + '}';
//   if (base && base.endsWith('/')) base = base.substr(0, base.length - 1);
//   return base;
// };

export const facebook = () =>
  'https://www.facebook.com/Bondspark-1938590349779069';
export const twitter = () => 'https://twitter.com/bondsparkSA';
export const instagram = () => 'https://www.instagram.com/bondspark/';
export const linkedin = () => 'https://www.linkedin.com/company/bondspark';
export const apply = ({ selectedBanks } = {}) => {
  if (!selectedBanks) return '/apply-now';

  return {
    pathname: '/apply-now',
    search: `banks=${selectedBanks.join(',')}`
  };
};
export const calculators = () => '/home-loan-calculator';
export const affordability = () => '/calculators/affordability';
export const repayment = () => '/calculators/repayment';
export const story = () => '/our-story';
export const testimonials = () => '/testimonials';
export const privacy = () => terms('privacy-policy');
export const terms = (section = '') =>
  `/terms-and-conditions${section ? `#${section}` : ''}`;
export const declarations = (section = '') =>
  `/application-declarations${section ? `#${section}` : ''}`;
export const prequalDeclarations = (section = '') =>
  `/prequal-declarations${section ? `#${section}` : ''}`;
export const learn = ({ lesson } = {}) => {
  if (!lesson) return '/learn';

  return `/learn#lesson-${lesson}`;
};
export const webinars = () => '/webinars';
// export const signIn = () =>
//   `${applicationUIBase()}/getting-started/applicant-details?continue=true`;

export const prequalify = () => '/prequalify';
export const faq = () => '/frequently-asked-questions';

export const applicationWizard = () =>
  'https://form.jotform.com/242116503145041?source=website';

export const prequalificationWizard = () =>
  'https://form.jotform.com/242381606003547?source=website';
